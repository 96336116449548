import {
  Classes,
  DefaultPopoverTargetHTMLProps,
  Intent,
  PopoverTargetProps,
} from "@blueprintjs/core";
import {
  ItemListRenderer,
  ItemRenderer,
  QueryList,
  QueryListRendererProps,
} from "@blueprintjs/select";
import {
  HexId,
  HexVersionAtomicOperation,
  convertRichTextToPlainText,
  groupBy,
  guardNever,
  notEmpty,
  typedObjectValues,
} from "@hex/common";
import { rgba } from "polished";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual } from "react-redux";
import styled, { css } from "styled-components";

import {
  HexButton,
  HexInputGroup,
  HexKeyCombo,
  HexMenu,
  HexMenuDivider,
  HexNonIdealState,
  HexSpinner,
  HexTooltip,
} from "../../../hex-components";
import { useCellContentsGetter } from "../../../hex-version-multiplayer/state-hooks/cellContentsStateHooks.js";
import { useCellsContentsSelector } from "../../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";
import { useCellsSelector } from "../../../hex-version-multiplayer/state-hooks/cellsStateHooks";
import { useHexSelector } from "../../../hex-version-multiplayer/state-hooks/hexStateHooks.js";
import { useHexVersionSelector } from "../../../hex-version-multiplayer/state-hooks/hexVersionStateHooks.js";
import { useEntitiesAddedToApp } from "../../../hooks/cell/useEntityAddedToApp.js";
import { useScrollToCell } from "../../../hooks/cell/useScrollToCell.js";
import { useProjectVersionEditable } from "../../../hooks/sessionOrProjectEditableHooks.js";
import { useDebouncedCallback } from "../../../hooks/useDebouncedCallback";
import {
  LocalStorageKeys,
  useLocalStorage,
} from "../../../hooks/useLocalStorage";
import { useProjectViews } from "../../../hooks/useProjectViews.js";
import {
  SessionStorageKeys,
  useSessionStorage,
} from "../../../hooks/useSessionStorage.js";
import { useToggleState } from "../../../hooks/useToggleState.js";
import { useDispatch, useSelector } from "../../../redux/hooks.js";
import { hexVersionMPSelectors } from "../../../redux/slices/hexVersionMPSlice.js";
import { setProjectSearch } from "../../../redux/slices/logicViewSlice.js";
import { getEditorForCellId } from "../../../state/models/useEditor.js";
import {
  getCellInputParams,
  getCellOutputParams,
  getSortedCells,
} from "../../../util/cellLayoutHelpers";
import { CellTypeToTypeName } from "../../../util/CellTypeNameToDisplay.js";
import { customScrollIntoView } from "../../../util/customScrollIntoView";
import { useHexVersionAOContext } from "../../../util/hexVersionAOContext.js";
import { HotKeys } from "../../../util/hotkeys";
import { Keys } from "../../../util/Keys";
import { useProjectContext } from "../../../util/projectContext";
import { useHexFlag } from "../../../util/useHexFlags.js";
import { PROJECT_DESCRIPTION_HTML_ID as PROJECT_DESCRIPTION_TAG } from "../../app/ProjectDescription.js";
import { ErrorBoundary } from "../../common/ErrorBoundary";
import { Heading } from "../../Heading";
import {
  ChangelogIcon,
  ClockIcon,
  MatchCaseIcon,
  MatchWholeWordIcon,
  SearchIcon,
  SingleChevronDownIcon,
  SingleChevronRightIcon,
} from "../../icons/CustomIcons";
import { PROJECT_TITLE_TAG } from "../../logic/ProjectMetadata.js";
import { SidebarDivider } from "../sidebarSharedStyles";

import { FilterProjectCells } from "./FilterProjectCells.js";
import { HighlightedLineMatch } from "./HighlightedLineMatch";
import { ProjectSearchResult } from "./ProjectSearchResult";
import { createRichTextElementsForSearch } from "./richTextUtils.js";
import {
  CellItem,
  IndexedSearchItem,
  ProjectMetadataItem,
  REGEX_GLOBAL_ALL_MATCHES,
  REGEX_GLOBAL_CAPS_MATCHES,
  SENTINEL_NO_MP_REPLACE_OPERATION,
  SearchableItem,
  cleanSearchTerm,
  getKeyForReplaceItems,
  getProjectSearchResultType,
  getReplaceFunctionDefinition,
  replaceAllForItemSource,
  replaceSingleLineItem,
  sourceToLines,
} from "./utils";

const OutlineViewContainer = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ProjectSearchListContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 5px;
`;

const SearchNonIdealState = styled(HexNonIdealState)`
  width: auto;
  margin: 0 12px;
  border: none;
`;

const CommandFEmptyState = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;
  margin: 0px 12px 10px 12px;
`;

export const CellTypeFilterList = styled(HexMenu)`
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 8px;
  overflow-y: auto;
  padding: 8px;
`;

const StyledHexKeyCombo = styled(HexKeyCombo)`
  position: relative;
  margin: 0px 2px;
  top: 2px;
  display: inline-flex;
  min-height: 0;
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;
  margin: 10px 12px 10px 4px;
  min-width: 0px;
`;

const StyledHexInputGroup = styled(HexInputGroup)`
  flex-grow: 1;
  min-width: 0;
`;
const ReplaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0px;
  padding-left: 25px;
  margin: 0px 12px 10px 0px;
`;

const CollapseReplaceIcon = styled(HexButton)`
  min-width: 0px;
`;

const ResultList = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0px;
  overflow: hidden;
`;

const ResultCount = styled.div`
  padding: 0 12px 0px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const MoreResultsWarning = styled.div`
  padding: 6px 12px 2px;
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
`;

const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 12px;
  align-items: center;

  & > *:only-child {
    margin-left: auto;
    margin-right: 12px;
  }
`;

const Results = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  padding-left: 0;
  overflow: auto;
`;

const RecentSearchList = styled.div`
  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const RecentSearchHeader = styled.div`
  display: flex;
  gap: 5px;
  padding: 12px 12px 8px;
  align-items: center;

  & > * {
    color: ${({ theme }) => theme.fontColor.MUTED};
  }
`;

const RecentSearchResult = styled(HexButton)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 12px;
  overflow: hidden;

  .${Classes.ICON} {
    display: none;
  }

  &:hover {
    .${Classes.ICON} {
      display: block;
    }
  }
`;

const StyledSearchBarButton = styled(HexButton)`
  top: 1px;
  position: relative;
  margin: 0 8px;
`;

const ToggleButton = styled(HexButton)<{
  $added: boolean;
}>`
  top: 1px;
  position: relative;
  ${({ $added, theme }) =>
    $added &&
    css`
      box-shadow: inset 0 0 0 1px ${rgba(theme.intent.PRIMARY, 0.5)};
      border-radius: ${theme.borderRadius};
      border: 1px solid ${rgba(theme.intent.PRIMARY, 0.5)};
    `};
`;

export interface ProjectSearchViewRef {
  focusAndSelect: () => void;
}

interface OccurenceIndexMatchConfig {
  caseMatch: boolean;
  exactWordMatch: boolean;
}

interface GetOccurenceIndexesArgs extends OccurenceIndexMatchConfig {
  searchableItem: string;
  searchTerm: string;
}

interface OccurenceIndexedResult {
  /**
   * The starting index of a searchable result.
   */
  start: number;
  /**
   * The ending index of a searchable result.
   * For now, this is just the length of the search term, but this needs to be updated to represent
   * a variable length so that we can support regex.
   */
  end: number;
}

interface GetOcurrencesResponse {
  results: OccurenceIndexedResult[];
  hasMore: boolean;
  error: boolean;
}

/**
 * global indicates return all matches, i indicates case insensitive.
 */
export const SEARCH_MAX_OCCURRENCES = 250;

const getOccurrenceIndexes = ({
  caseMatch,
  exactWordMatch,
  searchTerm,
  searchableItem,
}: GetOccurenceIndexesArgs): GetOcurrencesResponse => {
  if (searchTerm === "") return { results: [], hasMore: false, error: false };

  try {
    const cleanedSearchTerm = cleanSearchTerm(searchTerm, exactWordMatch);
    const flags = caseMatch
      ? REGEX_GLOBAL_CAPS_MATCHES
      : REGEX_GLOBAL_ALL_MATCHES;
    const regex = new RegExp(cleanedSearchTerm, flags);

    const allOccurrences: OccurenceIndexedResult[] = [];
    let match;

    while (
      (match = regex.exec(searchableItem)) != null &&
      allOccurrences.length < SEARCH_MAX_OCCURRENCES
    ) {
      if (regex.lastIndex === match.index) {
        regex.lastIndex++; // Avoid infinite loop for zero-length matches
      }
      allOccurrences.push({
        start: match.index,
        end: match.index + searchTerm.length,
      });
    }

    return {
      results: allOccurrences,
      hasMore: allOccurrences.length >= SEARCH_MAX_OCCURRENCES,
      error: false,
    };
  } catch (e) {
    console.error(e);
    return { results: [], hasMore: false, error: true };
  }
};

const uniqueIdForItem = (item: IndexedSearchItem): string => {
  let uniqueId = `item-${item.type}-${item.lineIndex}-${item.match?.startIndex}`;
  if ("cellId" in item) {
    uniqueId += `-${item.cellId}`;
  }
  return uniqueId;
};

export const ProjectSearchView = React.forwardRef<ProjectSearchViewRef>(
  // eslint-disable-next-line max-lines-per-function
  function ProjectSearchView(_props, ref) {
    const projectReplace = useHexFlag("project-find-and-replace");
    const richTextTreeTraversal = useHexFlag("proj-find-rich-text-traversal");
    const canEdit = useProjectVersionEditable();
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState<IndexedSearchItem | null>(
      null,
    );
    const { hexId, hexVersionId } = useProjectContext();
    const scrollToCell = useScrollToCell();
    const { hasAppViewOpen, hasNotebookViewOpen } = useProjectViews();
    const { projectTitle } = useHexSelector({
      selector: (hex) => ({
        projectTitle: hex.title,
      }),
      equalityFn: shallowEqual,
    });
    const { description } = useHexVersionSelector({
      selector: (hexVersion) => ({ description: hexVersion.description }),
      equalityFn: shallowEqual,
    });

    const [queryValue, setValueQuery] = useState("");
    const [replaceValue, setReplaceValue] = useState("");
    const [searchMatches, setSearchMatches] = useState<IndexedSearchItem[]>([]);
    /**
     * Grouped by either cells or by metadata.
     */
    const [countByGroup, setCountByGroupId] = useState<Record<string, number>>(
      {},
    );
    const [searchError, setSearchError] = useState<boolean>(false);
    const [caseMatch, , { toggle: toggleCaseMatch }] = useToggleState(false);
    const [cellFilterShowType] = useSessionStorage(
      SessionStorageKeys.CELL_FILTER_SHOW_TYPE(hexId),
    );
    const [exactWordMatch, , { toggle: toggleExactWordMatch }] =
      useToggleState(false);
    const [hasMoreResultsWarning, setHasMoreResultsWarning] =
      useToggleState(false);
    const [sidebarSearchTerm, setSidebarSearchTerm] = useLocalStorage(
      LocalStorageKeys.SIDEBAR_SEARCH_TERM({ hexId }),
    );
    const [allRecentSearches, setAllRecentSearches] = useLocalStorage(
      LocalStorageKeys.SIDEBAR_RECENTS(),
    );
    const recentSearchesEntryForHex = useMemo(
      () => allRecentSearches?.find((entry) => entry[0] === hexId),
      [allRecentSearches, hexId],
    );
    const recentSearches = useMemo(
      () => recentSearchesEntryForHex?.[1],
      [recentSearchesEntryForHex],
    );
    const [replaceToggle, setReplaceToggle] = useSessionStorage(
      SessionStorageKeys.PROJECT_REPLACE_OPENED(hexId),
    );

    const replaceModeEnabled = useMemo(
      () => replaceToggle && projectReplace && canEdit,
      [replaceToggle, projectReplace, canEdit],
    );

    const debouncedSetSidebarSearchTerm = useDebouncedCallback(
      setSidebarSearchTerm,
      500,
    );

    const handleEscapeKey = useCallback((event: KeyboardEvent) => {
      if (event.key === Keys.ESCAPE && searchInputRef.current) {
        searchInputRef.current.blur();
      }
    }, []);

    const searchInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      ref,
      (): ProjectSearchViewRef => ({
        focusAndSelect: () => {
          searchInputRef.current?.focus();
          searchInputRef.current?.select();
        },
      }),
      [],
    );

    const replaceInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      ref,
      (): ProjectSearchViewRef => ({
        focusAndSelect: () => {
          replaceInputRef.current?.focus();
          replaceInputRef.current?.select();
        },
      }),
      [],
    );

    useEffect(() => {
      document.addEventListener("keydown", handleEscapeKey);
      // Detach the event listener on cleanup
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }, [handleEscapeKey]);

    useEffect(() => {
      setValueQuery(sidebarSearchTerm);
    }, [sidebarSearchTerm]);

    const cells = useCellsSelector({
      selector: (cellsState) => cellsState,
    });
    const cellsContents = useCellsContentsSelector({
      selector: (cellsContentsState) => cellsContentsState,
    });
    const [allCells, cellIds] = useMemo(() => {
      const allSortedCells = getSortedCells(
        Object.values(cells)
          .filter(notEmpty)
          .filter((cell) => cell.deletedDate == null),
      );
      return [allSortedCells, allSortedCells.map(({ id }) => id)];
    }, [cells]);

    const cellsAddedToApp = useEntitiesAddedToApp({
      cellIds,
    });

    const searchableProjectMetadata: ProjectMetadataItem[] = useMemo(() => {
      const metadata: ProjectMetadataItem[] = [];
      if (projectTitle != null) {
        sourceToLines(projectTitle).map((line, index) => {
          metadata.push({
            type: "PROJECT_TITLE",
            lineIndex: index,
            lineSource: line,
            groupById: "PROJECT_TITLE",
            cellLabel: "Project title",
          });
        });
      }
      if (description != null) {
        sourceToLines(description).map((line, index) => {
          metadata.push({
            type: "PROJECT_DESCRIPTION",
            lineIndex: index,
            lineSource: line,
            groupById: "PROJECT_DESCRIPTION",
            cellLabel: "Project description",
          });
        });
      }
      return metadata;
    }, [description, projectTitle]);

    const cellLabels = useSelector((state) =>
      hexVersionMPSelectors
        .getCellLabelSelectors(hexVersionId)
        .selectCellIdToLabel(state),
    );

    // Combine all source cells with their sources
    const searchableCellLines: CellItem[] = useMemo(() => {
      return allCells.flatMap((cell) => {
        if (cell == null) {
          return [];
        }

        // If we are in app only mode, do not return anything from the cell unless the cell is included in the app.
        if (
          hasAppViewOpen &&
          !hasNotebookViewOpen &&
          !cellsAddedToApp[cell.id]
        ) {
          return [];
        }

        const cellTypename = cellFilterShowType.typename;
        const filterIsNotSet = cellTypename == null;
        if (cellTypename !== "All" && !filterIsNotSet) {
          const currentCellTypename = CellTypeToTypeName[cell.cellType];

          if (Array.isArray(cellFilterShowType.typename)) {
            if (!cellFilterShowType.typename.includes(currentCellTypename)) {
              return [];
            }
          } else if (currentCellTypename !== cellTypename) {
            return [];
          }
        }

        const searchLines: CellItem[] = [];
        const contents = cellsContents[cell.id];
        const cellLabel = cellLabels[cell.id] ?? "Cell";

        const cellSearchItemCommonFields = {
          cellType: cell.cellType,
          cellId: cell.id,
          cellLabel,
          cellOrder: cell.order,
          groupById: cell.id,
        };

        searchLines.push({
          ...cellSearchItemCommonFields,
          type: "CELL_LABEL" as const,
          lineSource: cellLabel,
          lineIndex: null,
        });

        let source = "";
        if (contents && "source" in contents) {
          source = contents.source;
        }

        if (contents && "richText" in contents) {
          // Feature flag the path for rich text node traversal.
          if (richTextTreeTraversal) {
            const richTextSearchableItems = createRichTextElementsForSearch(
              contents.richText,
            );
            searchLines.push(
              ...richTextSearchableItems.map(
                ({ lineSource, richTextElementPath }, index) => {
                  return {
                    ...cellSearchItemCommonFields,
                    type: "CELL_RICH_TEXT_ELEMENT" as const,
                    richTextElementPath,
                    lineSource,
                    lineIndex: index,
                  };
                },
              ),
            );
          } else {
            // Use legacy implementation of converting to plain text.
            // The cellType is type here will be TEXT, which does not have a replace function for the type 'CELL_LINE',
            // therefore it will not be replaceable in the legacy format.
            source = convertRichTextToPlainText(contents.richText);
          }
        }

        searchLines.push(
          ...sourceToLines(source).map((lineSource, lineIndex) => {
            return {
              ...cellSearchItemCommonFields,
              type: "CELL_LINE" as const,
              lineSource,
              lineIndex,
              fullSource: source,
            };
          }),
        );

        searchLines.push(
          ...getCellInputParams(contents).map((param) => ({
            ...cellSearchItemCommonFields,
            type: "CELL_INPUT" as const,
            lineSource: param,
            lineIndex: null,
          })),
        );
        searchLines.push(
          ...getCellOutputParams(contents).map((param) => ({
            ...cellSearchItemCommonFields,
            type: "CELL_OUTPUT" as const,
            lineSource: param,
            lineIndex: null,
          })),
        );
        return searchLines;
      });
    }, [
      allCells,
      hasAppViewOpen,
      hasNotebookViewOpen,
      cellsAddedToApp,
      cellFilterShowType.typename,
      cellsContents,
      cellLabels,
      richTextTreeTraversal,
    ]);

    const scrollIntoView = useCallback((id: string) => {
      const tag = document.getElementById(id);
      if (tag) {
        customScrollIntoView(tag, {
          scrollMode: "if-needed",
          behavior: "smooth",
        });
        (tag as HTMLElement).focus();
      }
    }, []);

    const onItemSelect = useCallback(
      (item: IndexedSearchItem) => {
        const lineNumber = item.lineIndex ?? 0;
        setActiveItem(item);

        // Depending on the item.type, we will have different scroll behavior.
        const itemType = item.type;
        switch (itemType) {
          case "PROJECT_TITLE": {
            scrollIntoView(PROJECT_TITLE_TAG);
            return;
          }
          case "PROJECT_DESCRIPTION": {
            scrollIntoView(PROJECT_DESCRIPTION_TAG);
            return;
          }
          case "CELL_LINE":
            scrollToCell(item.cellId, {
              scrollTarget: { type: "lineNumber", lineNumber },
            });
            break;
          case "CELL_OUTPUT":
            scrollToCell(item.cellId, {
              scrollTarget: "output",
            });
            break;
          case "CELL_RICH_TEXT_ELEMENT":
          case "CELL_INPUT":
          case "CELL_LABEL":
            scrollToCell(item.cellId, {
              scrollTarget: "source",
            });
            break;
          default:
            guardNever(itemType, itemType);
        }
      },
      [scrollToCell, scrollIntoView],
    );

    const goToNextSearchableItem = useCallback(
      (offset: number) => {
        if (activeItem?.listOffset == null) {
          if (searchMatches.length > 0) {
            setActiveItem(searchMatches[0]);
          }
          return;
        }

        let nextIndex = activeItem.listOffset + offset;
        if (nextIndex < 0) {
          nextIndex = searchMatches.length - 1;
        } else if (nextIndex >= searchMatches.length) {
          nextIndex = 0;
        }
        onItemSelect(searchMatches[nextIndex]);
      },
      [activeItem, onItemSelect, searchMatches],
    );

    const focusOnActiveItem = useCallback(() => {
      if (activeItem?.match != null) {
        const activeEditor = activeItem.cellId
          ? getEditorForCellId(activeItem.cellId)
          : null;

        if (activeEditor != null && activeItem.type === "CELL_LINE") {
          activeEditor.setPosition({
            lineNumber: (activeItem.lineIndex ?? 0) + 1,
            column: activeItem.match.endIndex + 1,
          });
          activeEditor.focus();
        }
      }
    }, [activeItem]);

    const handleKeyDownEvent = useCallback(
      async (event) => {
        switch (event.key) {
          // for up and down keys, go to the next item in the list.
          case Keys.ARROW_DOWN:
            goToNextSearchableItem(1);
            break;
          case Keys.ARROW_UP:
            goToNextSearchableItem(-1);
            break;
          // for the enter key, we want to focus on the current search term if there is an active editor.
          case Keys.ENTER:
            // use preventDefault to ensure no new line is created.
            event.preventDefault();
            focusOnActiveItem();
            break;
        }
      },
      [goToNextSearchableItem, focusOnActiveItem],
    );

    useEffect(() => {
      const aggregateResults: Record<string, number> = {};
      const finalizedOccurenceInput: SearchableItem[] = [
        ...searchableCellLines,
      ];
      if (
        cellFilterShowType.typename == null ||
        cellFilterShowType.typename === "All"
      ) {
        finalizedOccurenceInput.unshift(...searchableProjectMetadata);
      }
      const mappedMatches = finalizedOccurenceInput
        .filter(
          (item) =>
            !replaceToggle || getReplaceFunctionDefinition(item) != null,
        )
        .map((item) => {
          // if caps match enabled, do not lowercase results, otherwise return all.
          const {
            error,
            hasMore,
            results: occurrenceIndexes,
          } = getOccurrenceIndexes({
            searchableItem: item.lineSource,
            searchTerm: sidebarSearchTerm,
            caseMatch,
            exactWordMatch,
          });
          if (occurrenceIndexes.length === 0) {
            return { expandedMatches: [], hasMoreResults: hasMore, error };
          }

          if (aggregateResults[item.groupById] == null) {
            aggregateResults[item.groupById] = occurrenceIndexes.length;
          } else {
            aggregateResults[item.groupById] += occurrenceIndexes.length;
          }

          return {
            expandedMatches: occurrenceIndexes.map((occ) => ({
              ...item,
              match: {
                startIndex: occ.start,
                endIndex: occ.end,
              },
            })),
            hasMoreResults: hasMore,
            error,
          };
        });
      setSearchError(mappedMatches.some((m) => m.error));
      setHasMoreResultsWarning(mappedMatches.some((m) => m.hasMoreResults));
      setSearchMatches(
        mappedMatches
          .flatMap((m) => m.expandedMatches)
          .map((m, idx) => ({ ...m, listOffset: idx })),
      );
      setCountByGroupId(aggregateResults);
    }, [
      caseMatch,
      cellFilterShowType,
      cellFilterShowType.typename,
      exactWordMatch,
      searchableCellLines,
      searchableProjectMetadata,
      setHasMoreResultsWarning,
      sidebarSearchTerm,
      replaceModeEnabled,
      replaceToggle,
    ]);

    const getCellContents = useCellContentsGetter({ safe: true });
    const { dispatchAO } = useHexVersionAOContext();

    /**
     * Replaces a single cell item if an item is selected from the command line.
     */
    const replaceSingleTermCallback = useCallback(
      (item: SearchableItem) => {
        if (item.cellId == null) {
          return;
        }
        const cellContents = getCellContents(item.cellId);
        const mpOperation =
          cellContents != null
            ? replaceSingleLineItem(item, cellContents, replaceValue)
            : null;
        if (mpOperation != null) {
          dispatchAO(mpOperation);
        }
      },
      [dispatchAO, getCellContents, replaceValue],
    );

    /**
     * Callback function that will take all the searchMatches and create a single multiplayer operation
     * for the cellId-cellType combination. This ensures that when performing a replace all operation,
     * we only dispatch one operation per cell. This aggregation is necessary because each 'searchMatch'
     * in the results only represents a single cell line; if we dispatch an operation per search match,
     * they would be overwritten.
     */
    const replaceAllCellsCallback = useCallback(() => {
      const cellReplacementTypeToOperation: Record<
        string,
        HexVersionAtomicOperation | typeof SENTINEL_NO_MP_REPLACE_OPERATION
      > = {};
      searchMatches.forEach((cellItem) => {
        const key = getKeyForReplaceItems(cellItem);
        if (cellReplacementTypeToOperation[key] || cellItem.cellId == null) {
          return;
        }
        const cellContents = getCellContents(cellItem.cellId);
        if (cellContents != null) {
          cellReplacementTypeToOperation[key] =
            replaceAllForItemSource({
              cellItem,
              cellContents,
              replaceString: queryValue,
              replaceWith: replaceValue,
              caseMatch: caseMatch,
              exactWordMatch: exactWordMatch,
            }) ?? SENTINEL_NO_MP_REPLACE_OPERATION;
        }
      });
      const operations = typedObjectValues(
        cellReplacementTypeToOperation,
      ).filter((op) => op !== SENTINEL_NO_MP_REPLACE_OPERATION);

      dispatchAO(operations);
    }, [
      caseMatch,
      dispatchAO,
      exactWordMatch,
      getCellContents,
      queryValue,
      replaceValue,
      searchMatches,
    ]);

    const saveSearchToRecents = useCallback(() => {
      if (queryValue) {
        const newSearches = [...(recentSearches ?? [])];
        // if a term has already been searched, remove it so it can be re-added as most recent
        if (newSearches.includes(queryValue)) {
          newSearches.splice(newSearches.indexOf(queryValue), 1);
        }
        // only store 5 searched items per project
        newSearches.unshift(queryValue);
        while (newSearches.length > 5) {
          newSearches.pop();
        }
        // only store searches for a maximum of 5 projects
        const allOtherSearches =
          allRecentSearches?.filter((entry) => entry[0] !== hexId) ?? [];
        const newAllSearches: [HexId, readonly string[]][] = [
          [hexId, newSearches],
          ...allOtherSearches,
        ];
        while (allOtherSearches.length > 5) {
          allOtherSearches.pop();
        }
        setAllRecentSearches(newAllSearches);
      }
    }, [
      queryValue,
      recentSearches,
      setAllRecentSearches,
      hexId,
      allRecentSearches,
    ]);

    const toggleReplaceContainer = useCallback(() => {
      setReplaceToggle(!replaceToggle);
    }, [replaceToggle, setReplaceToggle]);

    const itemListRenderer: ItemListRenderer<IndexedSearchItem> = useCallback(
      ({ renderItem }) => {
        if (queryValue !== sidebarSearchTerm) {
          return <HexSpinner description="Searching..." />;
        }

        const cellMatchCount = searchMatches.filter(
          (match) => match.cellType != null,
        ).length;
        // Group results before rendering to add a label before each block of results per cell
        // and an affordance for a collapsible UI
        const groupedMatches = groupBy(searchMatches, "groupById");

        return (
          <>
            <FilterHeader>
              <ResultCount>
                {cellMatchCount} {replaceModeEnabled ? "replaceable" : ""}{" "}
                {cellMatchCount === 1 ? "result" : "results"}
              </ResultCount>
              <FilterProjectCells />
            </FilterHeader>
            {hasMoreResultsWarning ? (
              <MoreResultsWarning>
                Warning: The result set only contains a subset of matches. Be
                more specific in your search to narrow down the results.
              </MoreResultsWarning>
            ) : null}
            {queryValue.length > 0 && searchMatches.length === 0 ? (
              <SearchNonIdealState
                $minimal={true}
                $small={true}
                title="No results found"
              />
            ) : (
              <ResultList tabIndex={0} onKeyDown={handleKeyDownEvent}>
                <HexMenuDivider />
                <Results>
                  {Object.entries(groupedMatches).map(
                    (matchTuple: [string, IndexedSearchItem[]]) => {
                      const groupById = matchTuple[0];
                      const matchesForCell = matchTuple[1];
                      const firstMatch = matchesForCell[0];
                      return (
                        <ProjectSearchResult
                          key={`${groupById}-${firstMatch.type}-${firstMatch.cellType}`}
                          countsByGroup={countByGroup}
                          firstMatch={firstMatch}
                          groupId={groupById}
                          matchesForCell={matchesForCell}
                          renderItem={renderItem}
                          type={getProjectSearchResultType(firstMatch)}
                        />
                      );
                    },
                  )}
                </Results>
              </ResultList>
            )}
          </>
        );
      },
      [
        queryValue,
        sidebarSearchTerm,
        searchMatches,
        countByGroup,
        hasMoreResultsWarning,
        handleKeyDownEvent,
        replaceModeEnabled,
      ],
    );

    const itemRenderer: ItemRenderer<IndexedSearchItem> = useCallback(
      (item, { handleClick, modifiers: { active } }) => {
        return (
          <HighlightedLineMatch
            key={uniqueIdForItem(item)}
            active={active}
            handleClick={handleClick}
            handleReplaceItemCallback={replaceSingleTermCallback}
            id={uniqueIdForItem(item)}
            item={item}
            replaceValue={replaceModeEnabled ? replaceValue : null}
          />
        );
      },
      [replaceValue, replaceSingleTermCallback, replaceModeEnabled],
    );

    const setSearchTerm = useCallback(
      (term: string) => {
        setValueQuery(term);
        debouncedSetSidebarSearchTerm(term);
      },
      [debouncedSetSidebarSearchTerm],
    );

    // UseEffect to dispatch the current debounced project search string and the active selected item
    // to the Redux state store so that Monaco can highlight the text string
    useEffect(() => {
      dispatch(
        setProjectSearch({
          projectSearchTerm: sidebarSearchTerm,
          caseMatch,
          wholeWordMatch: exactWordMatch,
          selectedSearchItem:
            activeItem?.match && activeItem?.type === "CELL_LINE"
              ? {
                  cellId: activeItem.cellId,
                  lineIndex: (activeItem.lineIndex ?? 0) + 1,
                  match: activeItem?.match ?? null,
                }
              : undefined,
        }),
      );
    }, [
      sidebarSearchTerm,
      dispatch,
      caseMatch,
      exactWordMatch,
      activeItem?.cellId,
      activeItem?.lineIndex,
      activeItem?.match,
      activeItem?.type,
    ]);

    const onChangeQuery = useCallback(
      (event) => {
        setSearchTerm(event.target.value);
      },
      [setSearchTerm],
    );

    const onChangeReplaceString = useCallback(
      (event) => {
        setReplaceValue(event.target.value);
      },
      [setReplaceValue],
    );

    const selectRecentSearch = useCallback(
      (searchTerm: string) => {
        setValueQuery(searchTerm);
        setSidebarSearchTerm(searchTerm);
      },
      [setSidebarSearchTerm],
    );

    // Using render taget prevents an additional element render. This ensures
    // that the tooltip only appears when the mouse is hovering over the button.
    const renderTargetMatchCase = useCallback(
      ({
        as: _as,
        children: _children,
        ...targetProps
      }: PopoverTargetProps & DefaultPopoverTargetHTMLProps) => {
        return (
          <ToggleButton
            {...targetProps}
            $added={caseMatch}
            extraSmall={true}
            icon={<MatchCaseIcon />}
            minimal={true}
            type="button"
            onClick={toggleCaseMatch}
          />
        );
      },
      [caseMatch, toggleCaseMatch],
    );

    const renderTargetExactWordMatch = useCallback(
      ({
        as: _as,
        children: _children,
        ...targetProps
      }: PopoverTargetProps & DefaultPopoverTargetHTMLProps) => {
        return (
          <ToggleButton
            {...targetProps}
            $added={exactWordMatch}
            extraSmall={true}
            icon={<MatchWholeWordIcon />}
            minimal={true}
            type="button"
            onClick={toggleExactWordMatch}
          />
        );
      },
      [exactWordMatch, toggleExactWordMatch],
    );

    const isBrowserFindIntercepted = useHexFlag("intercept-browser-find");
    const projectSearchRenderer = useCallback(
      (listProps: QueryListRendererProps<IndexedSearchItem>) => {
        const { handleKeyDown, handleKeyUp, itemList } = listProps;

        return (
          <ProjectSearchListContainer
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          >
            <SearchContainer>
              {projectReplace && canEdit && (
                <CollapseReplaceIcon
                  extraSmall={true}
                  icon={
                    replaceToggle ? (
                      <SingleChevronDownIcon />
                    ) : (
                      <SingleChevronRightIcon />
                    )
                  }
                  minimal={true}
                  onClick={toggleReplaceContainer}
                />
              )}
              <StyledHexInputGroup
                autoFocus={true}
                inputRef={searchInputRef}
                intent={searchError ? Intent.DANGER : Intent.NONE}
                leftIcon={<SearchIcon />}
                placeholder="Find..."
                rightElement={
                  <>
                    <HexTooltip
                      content="Match case"
                      placement="bottom"
                      renderTarget={renderTargetMatchCase}
                    />
                    <HexTooltip
                      content="Exact word match"
                      placement="bottom"
                      renderTarget={renderTargetExactWordMatch}
                    />
                  </>
                }
                round={false}
                value={queryValue}
                onBlur={saveSearchToRecents}
                onChange={onChangeQuery}
              />
            </SearchContainer>
            {replaceModeEnabled && (
              <ReplaceContainer>
                <StyledHexInputGroup
                  autoFocus={true}
                  inputRef={replaceInputRef}
                  leftIcon={<ChangelogIcon />}
                  placeholder="Replace..."
                  rightElement={
                    <>
                      <StyledSearchBarButton
                        extraSmall={true}
                        intent={Intent.PRIMARY}
                        minimal={true}
                        onClick={replaceAllCellsCallback}
                      >
                        Replace all
                      </StyledSearchBarButton>
                    </>
                  }
                  round={false}
                  value={replaceValue}
                  onChange={onChangeReplaceString}
                />
              </ReplaceContainer>
            )}
            {!queryValue && isBrowserFindIntercepted && (
              <CommandFEmptyState>
                Press
                <StyledHexKeyCombo
                  combo={
                    isBrowserFindIntercepted
                      ? HotKeys.OPEN_PROJECT_SEARCH
                      : HotKeys.OPEN_PROJECT_SEARCH_ORIGINAL
                  }
                />
                again to use native browser search.
              </CommandFEmptyState>
            )}
            {!queryValue ? (
              !recentSearches ? (
                <SearchNonIdealState
                  $small={true}
                  description={
                    <div>
                      Use
                      <StyledHexKeyCombo
                        combo={
                          isBrowserFindIntercepted
                            ? HotKeys.OPEN_PROJECT_SEARCH
                            : HotKeys.OPEN_PROJECT_SEARCH_ORIGINAL
                        }
                      />
                      to search
                    </div>
                  }
                  icon={<SearchIcon />}
                  title="Enter a search term"
                />
              ) : (
                <RecentSearchList>
                  <RecentSearchHeader>
                    <ClockIcon />
                    <Heading renderAs="h5">Recent</Heading>
                  </RecentSearchHeader>
                  {recentSearches.map((searchTerm) => (
                    <RecentSearchResult
                      key={searchTerm}
                      minimal={true}
                      rightIcon={<SearchIcon />}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => selectRecentSearch(searchTerm)}
                    >
                      {searchTerm}
                    </RecentSearchResult>
                  ))}
                </RecentSearchList>
              )
            ) : (
              itemList
            )}
          </ProjectSearchListContainer>
        );
      },
      [
        projectReplace,
        canEdit,
        replaceToggle,
        toggleReplaceContainer,
        searchError,
        renderTargetMatchCase,
        renderTargetExactWordMatch,
        queryValue,
        saveSearchToRecents,
        onChangeQuery,
        replaceModeEnabled,
        replaceAllCellsCallback,
        replaceValue,
        onChangeReplaceString,
        isBrowserFindIntercepted,
        recentSearches,
        selectRecentSearch,
      ],
    );

    // QueryList's scrolling behavior can be somewhat erratic when there are non-selectable items
    // (ie our label elements) interspersed within the list of results. Instead, we manually control
    // the activeItem logic and implement our own smooth scroll behavior
    const handleActiveItemChange = useCallback(
      (item: IndexedSearchItem | null) => {
        if (item) {
          setActiveItem(item);
          // handles scrolling in the search sidebar
          const domElement = document.getElementById(uniqueIdForItem(item));
          if (domElement) {
            customScrollIntoView(domElement, {
              scrollMode: "if-needed",
              behavior: "smooth",
            });
          }
        } else {
          setActiveItem(null);
        }
      },
      [setActiveItem],
    );

    return (
      <OutlineViewContainer>
        <ErrorBoundary>
          <SidebarDivider />
          <QueryList<IndexedSearchItem>
            activeItem={activeItem}
            itemListRenderer={itemListRenderer}
            itemRenderer={itemRenderer}
            items={searchMatches}
            renderer={projectSearchRenderer}
            scrollToActiveItem={true}
            onActiveItemChange={handleActiveItemChange}
            onItemSelect={onItemSelect}
            onQueryChange={onChangeQuery}
          />
        </ErrorBoundary>
      </OutlineViewContainer>
    );
  },
);
